import React from "react";
import {
  Drawer,
  Space,
  Select,
  Tooltip,
  Button,
  Form,
  Row,
  Col,
  Input,
  ColorPicker,
} from "antd";
import * as Icons from "@ant-design/icons";

const EdgeEditor = ({ data, onSave, onClose, onChange }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let updatedData = { ...data, ...values };

        onSave(updatedData);
        form.resetFields();
      })
      .catch((error) => {
        console.error("Error al enviar el formulario:", error);
      });
  };

  const handle_OnChange = (_data, value) => {
    data[_data] = value;
    onChange(data);
  };

  const handleFormClose = () => {
    onClose();
    form.resetFields();
  };
  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  function hsvToHex(h, s, v) {
    // Asegúrate de que h esté en el rango [0, 360], s y v en [0, 1]
    h = ((h % 360) + 360) % 360;
    s = Math.min(1, Math.max(0, s));
    v = Math.min(1, Math.max(0, v));

    const c = v * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = v - c;

    let r, g, b;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    const intR = Math.round((r + m) * 255);
    const intG = Math.round((g + m) * 255);
    const intB = Math.round((b + m) * 255);

    const hexR = intR.toString(16).padStart(2, "0");
    const hexG = intG.toString(16).padStart(2, "0");
    const hexB = intB.toString(16).padStart(2, "0");

    return `#${hexR}${hexG}${hexB}`;
  }

  const generateFormFields = () => {
    if (!data) {
      return null;
    }

    return Object.entries(data).map(([key, valor]) => {
      let inputValue = valor;
      //if (typeof valor === 'object') {
      //        inputValue = JSON.stringify(inputValue);
      //}
      const titulos = {
        label: "Etiqueta de la conexion",
        type:
          "Tipo de conector default step smoothstep simplebezier" + data.type,
        color: "Color del conector",
      };
      const objetos = {
        label: (
          <Input
            key={key}
            campo={key}
            value={inputValue}
            onChange={(value) => handle_OnChange("label", value)}
            showCount
          />
        ),
        type: (
          <Select
            onChange={(value) => handle_OnChange("type", value)}
            required
            options={[
              { value: "default", label: "Default" },
              { value: "step", label: "Step" },
              { value: "smoothstep", label: "Smooth Step" },
              { value: "straight", label: "Straight" },
              { value: "simplebezier", label: "Simple Bezier" },
            ]}
          />
        ),
      };
      return (
        objetos[key] && (
          <Row key={"edge_row_pro" + key}>
            <Col key={"edge_col_pro" + key} span={24}>
              <Form.Item
                key={key}
                style={{ fontWeight: "bold" }}
                initialValue={inputValue}
                name={key}
                label={capitalizeFirstLowercaseRest(key)}
                tooltip={{
                  title: titulos[key],
                  icon: <Icons.InfoCircleOutlined />,
                }}
              >
                {objetos[key] || ""}
              </Form.Item>
            </Col>
          </Row>
        )
      );
    });
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={handleFormClose}
      open={true}
      width={300}
      title="Editar Edge"
      extra={
        <Space>
          <Tooltip title="Save data">
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={handleFormSubmit}
            >
              Save
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        form={form}
        onFinish={handleFormSubmit}
      >
        <div
          key={"egdelist"}
          className="scroll-pretty"
          style={{ height: "75vh", overflowY: "scroll" }}
        >
          {generateFormFields()}
          <Row>
            <Col span={24}>
              <Form.Item
                key={"color8"}
                style={{ fontWeight: "bold" }}
                initialValue={data.style.stroke || "#000000"}
                name={"color"}
                label={capitalizeFirstLowercaseRest("color")}
                tooltip={{
                  title: "Color del conector",
                  icon: <Icons.InfoCircleOutlined />,
                }}
              >
                <ColorPicker
                  format="hsv"
                  onChange={(value) => handle_OnChange("color", value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Drawer>
  );
};

export default EdgeEditor;
