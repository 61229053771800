import React, { useEffect, useState } from "react";
import DataTable from "../Components/DataTable";
import { API_Request } from "../Services/APIService";
import * as Icons from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { FormBuilder } from "../Components/FormBuilder";
import { Spin, Row, Col, Modal } from "antd";
import { showSuccessNotification } from "./../Services/notifications";
import { FormSecondaryWindow } from "./../Components/FormSecondaryWindow";

const dataTable_params = {
  entity: "accounts",
  main: "name",
  key: "id",
  new: ["name", "description", "image"],
  rows: 10,
  fieldsForm: [
    { name: "id", label: "Id", required: true, width: "400" },
    { name: "name", label: "nombre completo", required: true },
    { name: "description", label: "description completo" },
    { name: "image", label: "image completo" },
  ],
  actions: [
    {
      icon: <Icons.SettingOutlined style={{ fontSize: "20px" }} />,
      link: "accounts",
      title: "Settings",
    },
  ],
};

const dataTable_params_flows = {
  entity: "flows",
  main: "name",
  key: "id",
  new: ["name"],
  rows: 50,
  fieldsForm: [
    { name: "name", label: "nombre", required: true },
    { name: "flow_type", label: "flow_type" },
  ],
  actions: [
    {
      icon: <Icons.SettingOutlined style={{ fontSize: "20px" }} />,
      link: "flows",
      title: "Settings",
    },
  ],
};

const dataTable_params_bots = {
  entity: "bots",
  main: "name",
  key: "id",
  new: ["name"],
  rows: 50,
  fieldsForm: [{ name: "name", label: "nombre", required: true }],
  actions: [
    {
      icon: <Icons.SettingOutlined style={{ fontSize: "20px" }} />,
      link: "bots",
      title: "Settings",
    },
    {
      icon: <Icons.PullRequestOutlined style={{ fontSize: "20px" }} />,
      link: "bots/builder",
      title: "Builder",
    },
  ],
};

const AccountsPage = () => {
  const [isSecondaryWindowVisible, setSecondaryWindowVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [secondary_data, setsecondary_data] = useState({});

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const entity = "Accounts";

  const handle_CloseSecodaryWindow = () => {
    setSecondaryWindowVisible(false);
  };
  const handle_OpentoCreateSecodaryWindow = (data) => {
    console.log("DATA", data);
    setsecondary_data(data);
    setSecondaryWindowVisible(true);
  };

  const handle_RowSecondaryWindow = (data) => {
    setsecondary_data(data);
    setSecondaryWindowVisible(true);
  };

  const handle_DeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handle_DeleteSecodaryWindow = (data) => {
    setSecondaryWindowVisible(false);
    setDeleteModalVisible(true);
  };

  const handle_SaveSecodaryWindow = (_data) => {
    setSecondaryWindowVisible(false);

    if (_data.id) {
      let url = entity.toLowerCase() + "/" + _data.id;
      API_Request("PUT", url, _data.payload)
        .then((result) => {
          showSuccessNotification("Data update!!!");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    } else {
      let url = _data.entity.toLowerCase();
      let body_aux = { ..._data.payload, idAccount: data.id };
      API_Request("POST", url, body_aux)
        .then((result) => {
          showSuccessNotification("Data saved!!!");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
  };

  const handle_DeleteConfirm = () => {
    let url = entity.toLowerCase() + "/" + secondary_data.id;

    API_Request("DELETE", url)
      .then((result) => {
        let newData = {};

        if (secondary_data.entity === "security") {
          newData = {
            ...data,
            webhook: data.security.filter(
              (item) => item.id !== secondary_data.id,
            ),
          };
        } else {
          newData = {
            ...data,
            webhook: data.webhook.filter(
              (item) => item.id !== secondary_data.id,
            ),
          };
        }
        setData(newData);
        showSuccessNotification("Data erased!!!");
        setDeleteModalVisible(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setLoading(true);

    if (id !== "new") {
      let url = id
        ? "/" + entity.toLowerCase() + "/" + id
        : "/" + entity.toLowerCase();
      API_Request("GET", url)
        .then((result) => {
          setData(result.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id]);
  if (data === undefined || (data.length < 1 && id !== "new")) {
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );
  }

  return id !== undefined && id !== "new" ? (
    <>
      <Row>
        <Col offset={6} span={12}>
          <h1>{entity.slice(0, -1)}</h1>
          {!loading && (
            <FormBuilder
              entity={entity.toLowerCase()}
              formData={data}
              formParams={dataTable_params.fieldsForm}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col span={14}>
          <h1>Flows</h1>
          {data.Flows && (
            <DataTable
              onChange={null}
              loading={loading}
              data={data.Flows}
              params={dataTable_params_flows}
              onCreateClick={handle_OpentoCreateSecodaryWindow}
            />
          )}
        </Col>
        <Col offset={2} span={8}>
          <h1>Bots</h1>
          {data.Flows && (
            <DataTable
              onChange={null}
              loading={loading}
              data={data.Bots}
              params={dataTable_params_bots}
              onCreateClick={handle_OpentoCreateSecodaryWindow}
            />
          )}
        </Col>
      </Row>
      {isSecondaryWindowVisible && (
        <FormSecondaryWindow
          title="Detail"
          data={secondary_data}
          onSave={handle_SaveSecodaryWindow}
          onClose={handle_CloseSecodaryWindow}
          onDelete={handle_DeleteSecodaryWindow}
        />
      )}
    </>
  ) : (
    id !== "new" && (
      <Row>
        <Col offset={6} span={12}>
          <h1>{entity}</h1>
          <DataTable
            loading={loading}
            data={data}
            params={dataTable_params}
            onClick={handle_RowSecondaryWindow}
            onCreateClick={handle_OpentoCreateSecodaryWindow}
          />
          {isSecondaryWindowVisible && (
            <FormSecondaryWindow
              title="Detail"
              data={secondary_data}
              onSave={handle_SaveSecodaryWindow}
              onClose={handle_CloseSecodaryWindow}
              onDelete={handle_DeleteSecodaryWindow}
            />
          )}
          <Modal
            title="Confirm Delete"
            open={deleteModalVisible}
            onOk={handle_DeleteConfirm}
            onCancel={handle_DeleteCancel}
          >
            <p>Are you sure you want to delete ?</p>
          </Modal>
        </Col>
      </Row>
    )
  );
};

export default AccountsPage;
