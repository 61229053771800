import React from "react";

const Home = ({ username }) => {
  return (
    <>
      <h1>Welcome, {username}!</h1>
    </>
  );
};

export default Home;
