import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  MarkerType,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "reactflow";
import "reactflow/dist/style.css";
import { Badge } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import {
  ConnectionLine,
  BuilderLibrary,
  BlockConditional,
  BlockStart,
  BlockGeneric,
  BlockEnd,
  BlockHttpRequest,
  BlockHttpRequestSimple,
  BlockImage,
  BlockOption,
  BlockinputText,
  BlockMessage,
} from "./BuilderLibrary";
import "./BuilderLibrary.css";
import NodeEdge from "./NodeEdge";
import EdgeEditor from "./EdgeEditor";
import { Layout, Button, Spin } from "antd";
import Sider from "antd/es/layout/Sider";
import { useBlocker, useParams } from "react-router-dom";
import { showSuccessNotification } from "../Services/notifications";
import { API_Request, fetchAndSerializeFlow } from "./../Services/APIService";
import { NodesContext } from "../contexts/NodesContext";
import {
  EXIT_BUILDER_MESSAGE,
  hsvToHex,
  TEMPORAL_NODE_ID_PREFIX,
} from "../helpers";

const nodeTypes = {
  start: BlockStart,
  log: BlockGeneric,
  message: BlockMessage,
  conditional: BlockConditional,
  switch: BlockOption,
  inputText: BlockinputText,
  inputDate: BlockGeneric,
  inputNumber: BlockGeneric,
  setVariable: BlockGeneric,
  setAtribute: BlockGeneric,
  optionButton: BlockOption,
  optionList: BlockOption,
  image: BlockImage,
  file: BlockGeneric,
  audio: BlockGeneric,
  video: BlockGeneric,
  sticker: BlockImage,
  location: BlockGeneric,
  document: BlockGeneric,
  HttpRequest: BlockHttpRequest,
  HttpRequestSimple: BlockHttpRequestSimple,
  goto: BlockGeneric,
  end: BlockEnd,
};

const edgeTypes = {
  buttonedge: NodeEdge,
};

const edgeOptions = {
  animated: false,
  type: "smoothstep",
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 12,
    height: 12,
    color: "#99a1e5",
  },
  style: { strokeWidth: 2, stroke: "#99a1e5" },
};

export const Builder = () => {
  const { id } = useParams();
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(null);
  const [isEdgeEditorVisible, setEdgeEditorVisible] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [copiedNodes, setCopiedNodes] = useState(null);
  const { nodeEditorSaved } = useContext(NodesContext);
  const [isDirty, setIsDirty] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  const getSetFlow = async () => {
    try {
      // Hacer la solicitud fetch para obtener la estructura de nodos y edges
      const { serializedNodes, serializedEdges } = await fetchAndSerializeFlow({
        id,
      });
      setNodes(serializedNodes);
      setEdges(serializedEdges);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFlow = async () => {
    try {
      if (reactFlowInstance) {
        const flow = reactFlowInstance.toObject();
        // actualizar nodos en backend y db
        await API_Request("PUT", `/bots/nodes/${id}`, flow);
        await getSetFlow()
        showSuccessNotification("All data saved!!!");
        setIsDirty(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
      setIsDirty(true);
    },
    [setEdges]
  );

  const handleEdgeEditorClose = () => {
    setEdgeEditorVisible(false);
  };

  const handleEdgeEditorChange = (updateData) => {
    const h = updateData.color?.metaColor?.originalInput?.h || 0; // Valor predeterminado 0 si no está definido
    const s = updateData.color?.metaColor?.originalInput?.s || 0;
    const v = updateData.color?.metaColor?.originalInput?.v || 0;
    let color_aux = hsvToHex(h, s, v) || "#99a1e5";
    const updatedEdge = {
      ...updateData,
      label: updateData.label,
      markerEnd: {
        type: "arrowclosed",
        height: 12,
        width: 12,
        color: color_aux,
      },
      style: { stroke: color_aux },
    };
    delete updatedEdge.color;
    const updatedEdges = edges.map((item) =>
      item.id === updateData.id ? updatedEdge : item,
    );
    setEdges(updatedEdges);
  };

  const handleEdgeEditorSave = (updateData) => {
    handleEdgeEditorChange(updateData);
    setEdgeEditorVisible(false);
  };

  const onEdgeClick = (event, edge_aux) => {
    event.stopPropagation(); // Evita que el evento de clic se propague al flujo
    const updatedEdge = { ...edge_aux, type: "step", animated: true };

    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item,
    );
    setEdges(updatedEdges);
    setSelectedEdge(updatedEdge);
    setEdgeEditorVisible(true);
  };

  const onEdgeMouseEnter = (event, edge_aux) => {
    event.stopPropagation();
    const updatedEdge = { ...edge_aux, animated: true };
    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item,
    );
    setEdges(updatedEdges);
  };

  const onEdgeMouseLeave = (event, edge_aux) => {
    event.stopPropagation();
    const updatedEdge = { ...edge_aux, animated: false };
    const updatedEdges = edges.map((item) =>
      item.id === edge_aux.id ? updatedEdge : item,
    );
    setEdges(updatedEdges);
  };

  const addNode = (node) => {
    node.id = `${TEMPORAL_NODE_ID_PREFIX}-${node.type}-${Math.floor(
      Math.random() * 1000000
    )}`;
    setNodes((nds) => nds.concat(node));
    setIsDirty(true);
  };

  const handleBeforeUnload = useCallback(
    (event) => {
      if (isDirty) {
        event.returnValue = EXIT_BUILDER_MESSAGE;
        return EXIT_BUILDER_MESSAGE;
      }
    },
    [isDirty]
  );

  const onSave = useCallback(saveFlow, [id, reactFlowInstance]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodesDelete = useCallback(
    async (deleted) => {
      deleted.forEach(async (node) => {
        try {
          // si el nodo es temporal, no se borra en la base de datos
          if (node.id.startsWith(TEMPORAL_NODE_ID_PREFIX)) return;
          // borrar de base de datos
          await API_Request("DELETE", `bots/nodes/${node.id}`);
        } catch (e) {
          console.log("No es posible borrar");
        }
      });
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge),
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            })),
          );

          return [...remainingEdges, ...createdEdges];
        }, edges),
      );
    },
    [setEdges, edges, nodes],
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const node = JSON.parse(
        event.dataTransfer.getData("application/flow-iteractions"),
      );

      showSuccessNotification("Element Added!");
      node.name = "New " + type;
      // check if the dropped element is valid

      try {
        if (
          !type ||
          (type === "start" &&
            nodes.find((element) => element.type === "start")?.type ===
              "start") ||
          (type === "end" &&
            nodes.find((element) => element.type === "end")?.type === "end")
        ) {
          console.log("NO");
          return false;
        }
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        const newNode = {
          node_type: type,
          type: type,
          position,
          data: { label: `${type} node`, type: type, payload: node },
        };
        addNode(newNode);
      } catch (e) {
        console.log(e);
      }
    },
    [id, nodes, reactFlowInstance, setNodes],
  );

  const handleKeyDown = useCallback(
    async (event) => {
      // fire only if its a node
      if (
        document.activeElement.classList.contains("react-flow__node") ||
        document.activeElement.classList.contains(
          "react-flow__nodesselection-rect"
        )
      ) {
        if (event.ctrlKey || event.metaKey) {
          switch (event.key) {
            case "c":
              const selectedNodes = nodes.filter((node) => node.selected);
              if (selectedNodes) {
                setCopiedNodes(selectedNodes);
              }
              break;
            case "v":
              if (copiedNodes) {
                for await (const copiedNode of copiedNodes) {
                  const newNode = {
                    node_type: copiedNode.type,
                    type: copiedNode.type,
                    position: {
                      x: copiedNode.position.x + 50,
                      y: copiedNode.position.y + 50,
                    },
                    data: { ...copiedNode.data },
                  };
                  addNode(newNode);
                }
              }
              break;
            default:
              break;
          }
        }
      }
    },
    [nodes, copiedNodes],
  );

  useEffect(() => {
    getSetFlow();
  }, [id, setEdges, setNodes]);

  useEffect(() => {
    if (nodeEditorSaved) {
      setIsDirty(true);
    }
  }, [nodeEditorSaved]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const res = window.confirm(EXIT_BUILDER_MESSAGE);
      res ? blocker.proceed() : blocker.reset();
    }
  }, [blocker]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  if (!nodes) {
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );
  }

  return (
    <Layout style={{ minHeight: "90vh" }}>
      <Sider
        theme="light"
        width={"130"}
        className={"scroll-pretty"}
        style={{ overflow: "auto", height: "90vh" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <BuilderLibrary></BuilderLibrary>
      </Sider>
      <div style={{ height: "90vh", width: "100%" }}>
        <ReactFlowProvider>
          <ReactFlow
            ref={reactFlowWrapper}
            defaultEdgeOptions={edgeOptions}
            deleteKeyCode={["Delete"]}
            elements={nodes}
            nodes={nodes}
            edges={edges}
            onNodeDrag={() => setIsDirty(true)}
            onNodesChange={onNodesChange}
            onNodesDelete={onNodesDelete}
            onEdgeDoubleClick={onEdgeClick}
            onEdgeMouseEnter={onEdgeMouseEnter}
            onEdgeMouseLeave={onEdgeMouseLeave}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={() => setIsDirty(true)}
            onConnect={onConnect}
            proOptions={{ hideAttribution: true }}
            style={{ background: "#f7f7f7" }}
            fitView
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            snapToGrid={true}
            connectionLineComponent={ConnectionLine}
            fitViewOptions={{ padding: 0.1, minZoom: 0.2, maxZoom: 2 }}
            onKeyDown={handleKeyDown}
          >
            <Controls showInteractive={true}>
              <Button
                style={{ padding: "5px", backgroundColor: "#ffffff" }}
                type="text"
                onClick={onSave}
              >
                <Badge dot count={isDirty ? 1 : 0}>
                  <SaveOutlined />
                </Badge>
              </Button>
            </Controls>
            <MiniMap style={{ height: 100 }} zoomable pannable />
            <Background variant="lines" gap={30} size={5} />
          </ReactFlow>
        </ReactFlowProvider>
        {isEdgeEditorVisible && (
          <EdgeEditor
            onSave={handleEdgeEditorSave}
            onClose={handleEdgeEditorClose}
            onChange={handleEdgeEditorChange}
            data={selectedEdge}
          />
        )}
      </div>
    </Layout>
  );
};
