import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Space, Button, Avatar, Tooltip, Card } from "antd";
import * as Icons from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const DataTable = ({
  loading,
  data,
  onChange,
  params,
  context,
  onCreateClick,
  onClick,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pageSize, setPageSize] = useState(params.rows || 10);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const searchInput = useRef(null);
  //const navigate = useNavigate();

  let data_final = [];
  if (Array.isArray(data)) {
    data_final = data.map((item) => {
      return { ...item, key: item.id };
    });
  }

  let columns = null;
  if (data[0]) {
    columns = params.fieldsForm
      .filter(
        (item) =>
          (item.required || item.column) &&
          item.name !== "id" &&
          item.name !== "password",
      )
      .map((item, index) => ({
        ...item,
        title: item.label
          ? item.label.charAt(0).toUpperCase() +
            item.label.slice(1).replace("_", " ")
          : item.name,
        dataIndex: item.name,
        key: item.name,
      }));
  }
  // Función para verificar si la fecha es válida en formato ISO 8601
  const isValidISODate = (dateString) => {
    const regEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return regEx.test(dateString);
  };

  // Función para formatear la fecha en formato DD-MM-YYYY HH:mm:ss
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${addLeadingZero(date.getDate())}-${addLeadingZero(date.getMonth() + 1)}-${date.getFullYear()} ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}:${addLeadingZero(date.getSeconds())}`;
    return formattedDate;
  };

  // Función para agregar un cero inicial a los números menores a 10
  const addLeadingZero = (number) => {
    return number < 10 ? `0${number}` : number;
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        key={`div_search_${dataIndex}`}
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          key={`key_input_search_${dataIndex}`}
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            key={`key_button_search_${dataIndex}`}
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            key={`key_buttonClear_search_${dataIndex}`}
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            key={`key_buttonFilter_search_${dataIndex}`}
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            key={`key_buttonClose_search_${dataIndex}`}
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icons.SearchOutlined
        style={{ color: filtered ? "#1677ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) => {
      let formattedText = text;
      if (typeof text === "string" && isValidISODate(text)) {
        formattedText = formatDate(text);
      }
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : dataIndex === "avatar" ? (
        <Avatar
          style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
          src={text}
        >
          {text || record["avatar"]
            ? record["avatar"].slice(0, 3)
            : record["username"].slice(0, 3)}
        </Avatar>
      ) : (
        formattedText
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handle_onCreateClick = (value) => {
    const aux = {
      entity: params.entity,
      value: value,
      fieldsForm: params.fieldsForm,
      payload: {},
    };
    onCreateClick(aux);
  };

  const rowClassName = (record) => {
    return record.id === selectedRowId ? "ant-table-row-selected" : ""; // Aplica la clase "selected-row" si el id coincide
  };

  const handle_onClick = (record) => {
    if (onClick) {
      const aux = {
        entity: params.entity,
        id: record.id,
        fieldsForm: params.fieldsForm,
        payload: record,
      };
      onClick(aux);
    }
  };
  const handleRowClick = (record) => {
    setSelectedRowId(record.id);
    if (onChange) {
      const aux = {
        entity: params.entity,
        id: record.id,
        fieldsForm: params.fieldsForm,
        payload: record,
      };
      onChange(aux);
    }
  };

  const renderColumns =
    columns !== null
      ? columns.map((column) => {
          const { key, title, dataIndex } = column;
          const columnProps = {
            key: key,
            title: title,
            width: column.width + "px",
            ellipsis: true,
            dataIndex: dataIndex,
            sorter: {
              compare: (a, b) => {
                if (typeof a[key] === "number" && typeof b[key] === "number") {
                  return a[key] - b[key];
                } else {
                  if (a[key] === null || a[key] === undefined) {
                    return b[key];
                  }

                  if (b[key] === null || b[key] === undefined) {
                    return a[key];
                  }

                  return a[key].localeCompare(b[key]);
                }
              },
              multiple: column.multiple,
            },
            ...(key === "id" || key === "avatar" || key === "actions"
              ? { width: 100 }
              : {}),
            ...getColumnSearchProps(dataIndex),
          };

          if (key === params.main) {
            return {
              ...columnProps,
              render: (text, record) => (
                <div key={"mainTable" + record.id}>
                  {context === "onPage" ? (
                    <>{text}</>
                  ) : (
                    <Link
                      key={"link" + record.id}
                      to={`/${params.entity}/${record[params.key]}`}
                    >
                      {text}
                    </Link>
                  )}
                </div>
              ),
            };
          }

          return columnProps;
        })
      : [];
  if (params.actions) {
    renderColumns.push({
      key: "actions",
      title: "Actions",
      fixed: "right",
      dataIndex: "id",
      align: "center",
      width: 75,
      render: (text, record) => (
        <Space id={"space_" + text} key={"space_" + text} size="middle">
          {params.actions.map((action) => (
            <Tooltip
              id={"Tooltip" + record.id + action.title}
              key={"Tooltip" + record.id + action.title}
              title={action.title || "Details"}
            >
              {context === "onPage" ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handle_onClick(record);
                  }}
                  id={"Linkspan" + record.id}
                  key={"Linkspan" + record.id}
                >
                  {action.icon}{" "}
                </span>
              ) : (
                <Link
                  id={"Link" + record.id}
                  key={"Link" + action.key}
                  to={`/${action.link}/${record[action.key] !== undefined ? record[action.key] : record.id}`}
                >
                  <span
                    id={"Linkspan" + record.id}
                    key={"Linkspan" + record.id}
                  >
                    {action.icon}
                  </span>
                </Link>
              )}
            </Tooltip>
          ))}
        </Space>
      ),
    });
  }
  return (
    <>
      <Card>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "5px" }}
        >
          {params.create !== false && (
            <Tooltip title={"Add data"} placement="left">
              <Icons.PlusCircleOutlined
                onClick={() => {
                  handle_onCreateClick();
                }}
                style={{ fontSize: "25px", color: "#00aa00" }}
              />
            </Tooltip>
          )}
        </div>
        <Table
          size={"small"}
          rowClassName={rowClassName}
          className="flow_table"
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record); // Maneja el clic en la fila
            },
          })}
          loading={loading}
          dataSource={data_final}
          columns={renderColumns}
          pagination={
            params.pagination === true || params.pagination === undefined
              ? {
                  pageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  pageSizeOptions: ["5", "10", "50", "100", "500"], // Opciones de número de filas por página
                }
              : false
          }
        ></Table>
      </Card>
    </>
  );
};

export default DataTable;
