import React from "react";
import { Space, Image, Avatar, Card, Descriptions, Button } from "antd";
import * as Icons from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  APIProvider,
  Map,
  Marker,
  useMarkerRef,
} from "@vis.gl/react-google-maps";

const ChatMessage = ({ message }) => {
  const googleMapsApiKey = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
  const [markerRef, marker] = useMarkerRef();

  const ContactCard = ({ contact }) => {
    const { name, emails, phones } = contact;

    return (
      <Card style={{ margin: "20px auto" }}>
        <Card.Meta
          avatar={<Avatar icon={<Icons.UserOutlined />} />}
          title={name.formatted_name}
          description={name.first_name + " " + (name.last_name || "")}
        />
        <Descriptions
          bordered
          column={1}
          size="small"
          style={{ marginTop: "16px" }}
        >
          <Descriptions.Item label="First Name">
            {name.first_name}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">
            {name.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {emails[0]?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phones">
            {phones[0]?.phone}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    );
  };

  const onDownload = () => {
    fetch(message.payload.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const handle_maps_click = (coords) => {
    window.open(
      "https://www.google.com/maps/search/BP/@" +
        coords.lat +
        "," +
        coords.lng +
        ",17z?entry=ttu",
    );
  };

  if (message.payload?.payload?.url)
    message.payload.url = message.payload?.payload?.url || "...";
  if (message.payload?.payload?.name)
    message.payload.name = message.payload?.payload?.name || "...";
  if (message.payload?.payload?.contentType)
    message.payload.contentType =
      message.payload?.payload?.contentType || "...";
  if (message.author === "bot") {
    switch (message.type) {
      case "inputText":
      case "optionList":
      case "optionButton":
        const renderOptions = () => {
          if (message.payload?.options) {
            return message.payload.options.map((option) => (
              <Button key={option.key} block type="default">
                {option.value}
              </Button>
            ));
          }
          return null;
        };
        return (
          <Space direction="vertical" style={{ width: "100%" }}>
            {message.payload?.msg || "Unknown reply"}
            {renderOptions()}
          </Space>
        );
      case "message":
        return (
          message.payload?.msg ||
          message.payload?.text ||
          message.payload?.title ||
          message.payload?.payload?.text ||
          message.payload?.payload?.title ||
          message.payload?.payload?.payload?.text ||
          "vacio"
        );
      case "start":
      case "end":
        return message.type;
      default:
    }
  }
  switch (message.event) {
    case "document":
    case "file":
      return (
        <>
          {message.payload?.title ||
            message.payload.payload?.title ||
            message.payload?.text ||
            message.payload.payload?.payload?.text}{" "}
          <Link
            download
            target="_blank"
            rel="noreferrer"
            href={message.payload?.url}
            to={message.payload?.url}
          >
            <Icons.CloudDownloadOutlined
              style={{ fontSize: "20px" }}
            ></Icons.CloudDownloadOutlined>
            {message.payload?.name || message.payload?.payload?.name}
          </Link>
        </>
      );
    case "audio":
      return (
        <audio
          style={{
            maxHeight: "400px",
            paddingRight: "10px",
            marginRight: "-60px",
          }}
          controls={true}
          width={400}
          src={message.payload?.url || null}
        />
      );
    case "video":
      return (
        <video
          style={{ maxHeight: "400px" }}
          controls={true}
          width={"100%"}
          src={message.payload?.url || null}
        />
      );
    case "image":
    case "sticker":
      return (
        <Image
          style={{
            maxHeight: "400px",
            maxWidth: "250px",
            marginRight: "-21px",
          }}
          width={"100%"}
          alt={"Image"}
          src={
            message.payload?.url ||
            message.payload?.payload?.payload?.url ||
            null
          }
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              },
            ) => (
              <Space size={12} className="toolbar-wrapper">
                <Icons.DownloadOutlined onClick={onDownload} />
                <Icons.SwapOutlined rotate={90} onClick={onFlipY} />
                <Icons.SwapOutlined onClick={onFlipX} />
                <Icons.RotateLeftOutlined onClick={onRotateLeft} />
                <Icons.RotateRightOutlined onClick={onRotateRight} />
                <Icons.ZoomOutOutlined
                  disabled={scale === 1}
                  onClick={onZoomOut}
                />
                <Icons.ZoomInOutlined
                  disabled={scale === 50}
                  onClick={onZoomIn}
                />
              </Space>
            ),
          }}
        ></Image>
      );
    case "message":
    case "text":
      return (
        message.payload?.msg ||
        message.payload?.text ||
        message.payload?.title ||
        message.payload?.payload?.payload?.text ||
        message.payload?.payload?.title ||
        message.payload?.payload?.text
      );
    case "GPS":
    case "location":
      let lat_aux = parseFloat(
        message.payload?.latitude || message.payload?.payload?.latitude,
      );
      let lng_aux = parseFloat(
        message.payload?.longitude || message.payload?.payload?.longitude,
      );

      return (
        <APIProvider apiKey={googleMapsApiKey}>
          Google Maps
          <Map
            style={{ height: "150px" }}
            defaultCenter={{ lat: lat_aux, lng: lng_aux }}
            defaultZoom={13}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            onClick={() => handle_maps_click({ lat: lat_aux, lng: lng_aux })}
          >
            <Marker ref={markerRef} position={{ lat: lat_aux, lng: lng_aux }} />
          </Map>
        </APIProvider>
      );
    //return (
    //          'GPS '+ ((message.payload?.latitude|| message.payload?.payload?.latitude ) +' , ' +  (message.payload?.longitude ||  message.payload?.payload?.longitude  ))
    //)
    case "list_reply":
    case "button_reply":
      return (
        message.payload?.title ||
        message.payload?.payload?.title ||
        message.payload?.payload?.payload?.title ||
        "unknow reply"
      );
    case "contact":
      const contact =
        message.payload?.payload?.contacts?.[0] ||
        message.payload?.contacts?.[0] ||
        {};
      return <ContactCard contact={contact} />;
    default:
      return (
        <>
          {message.type} | {message.event}
        </>
      );
  }
};
export default ChatMessage;
