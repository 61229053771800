import React, { useContext } from "react";
import { Tooltip, Radio, Avatar, Tag } from "antd";
import * as Icons from "@ant-design/icons";
import ChatMessage from "./ChatMessage";
import { formatSpanishDate } from "../Services/APIService";
import { AuthContext } from "../Services/AuthService";

const MessageComponent = ({ message }) => {
  const { language } = useContext(AuthContext);
  const aux_avatar =
    message.sender?.avatar ||
    message.payload?.sender?.avatar ||
    message.payload?.payload?.sender?.avatar ||
    message.variables?.avatar;
  return (
    <span>
      {message.author === "customer" && (
        <div className="message_avatar">
          {aux_avatar ? (
            <Avatar
              style={{
                backgroundColor: "#fde3cf",
                color: "var(--moradoKonverting)",
              }}
              align={"bottom"}
              src={aux_avatar}
              size={35}
              justify={"middle"}
            >
              Agent
            </Avatar>
          ) : (
            <Icons.UserOutlined
              size={44}
              style={{ color: "var(--moradoKonverting)" }}
            />
          )}
        </div>
      )}

      <Tooltip
        title={`${message.authorName || message.authorname || message.author} ID ${message.id} ${formatSpanishDate(language, message.timestamp, true)}`}
      >
        <div
          id={"external_message_timestamp" + message.id}
          key={"external_message_timestamp" + message.id}
        >
          <div
            id={"timestamp" + message.id}
            key={"timestamp" + message.id}
            className={`timestamp timestamp_${message.author}`}
          >
            {formatSpanishDate(
              language,
              message.updatedAt || message.timestamp,
            )}
          </div>
          <div
            id={message.id}
            key={message.id}
            className={`message message_${message.author === "agent" || message.author === "myself" || message.author === "bot" || message.author === "system" || message.author === "IA" ? "sent" : "received"} message_${message.author} message_${message.type} external_message_${message.author}`}
          >
            <ChatMessage
              key={"chatmessage" + message.id}
              message={message}
            ></ChatMessage>
          </div>
        </div>
      </Tooltip>

      {message.payload?.url &&
        message.author !== "customer" &&
        message.author !== "agent" &&
        message.author !== "myself" && (
          <div
            id={"optionsPayload" + message.id}
            key={"optionsPayload" + message.id}
            className="options"
          >
            <Tag color={"blue"}>{message.payload?.url}</Tag>
          </div>
        )}

      {message.author !== "customer" && (
        <div className="message_avatar">
          {aux_avatar ? (
            <Avatar
              style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
              align={"bottom"}
              src={aux_avatar}
              size={35}
              justify={"middle"}
            >
              Agent
            </Avatar>
          ) : (
            <>
              {message.author === "agent" || message.author === "myself" ? (
                <Icons.CustomerServiceOutlined
                  size={44}
                  style={{ color: "var(--rosaKonverting)" }}
                />
              ) : null}
              {message.author === "bot" && (
                <Icons.BugOutlined size={44} style={{ color: "#a1a1a1" }} />
              )}
              {message.author === "system" && (
                <Icons.DesktopOutlined size={44} style={{ color: "#00990c" }} />
              )}
            </>
          )}
        </div>
      )}
    </span>
  );
};

export default MessageComponent;
