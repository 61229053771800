import React, { useState } from "react";
import { Input, Row, Col, Form, Button, Switch, Tooltip } from "antd";
import * as Icons from "@ant-design/icons";

//
// const OptionKeyValue = ({ type, value, onChange,campo }) => {
//   const [options, setOptions] = useState(value || []);
//   const [jsonview, setJsonView] = useState(typeof value === 'object' || (typeof value === 'string' && value.includes('$')));

//
//   const updateOptionsAndNotifyChange = (updatedValue) => {
//     setOptions(updatedValue);
//     onChange(campo, updatedValue);
//   };
//
//   const handleInputChange = (key, newValue, field) => {
//     console.log(key,newValue,field)
//     if (jsonview && (typeof value === 'object' )) {
//       console.log("pretty campo",campo)
//       let updatedValue = [...options];
//
//       updatedValue[key][field] = newValue;
//       console.log("updatedValue",updatedValue)
//   //
//       updateOptionsAndNotifyChange(updatedValue);
//
//     } else {
//       console.log("sucio",newValue)
//   //    onChange(campo,newValue);
//       let updatedValue = [];
//       try {
//         updatedValue = JSON.parse(newValue);
//       } catch (e) {
//
//       }
//       console.log("updatedValue",updatedValue)
//       updateOptionsAndNotifyChange(updatedValue);
//     }
//   };
//
//   const handle_resetValue = () =>{
//     let aux = [{"key":"variable","value":null}]
//     setJsonView(true)
//     onChange(aux);
//     setOptions(aux);
//   }
//
//   const handleView = () => {
//     setJsonView(!jsonview);
//   };
//

//

//
//   return (
//     <>
//       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <Tooltip title={'Reset values'}>
//            <Button onClick={()=>handle_resetValue()} size={'small'} style={{ alignSelf: 'flex-start' }}><Icons.ReloadOutlined /></Button>
//         </Tooltip>
//
//         <Switch
//           style={{ justifyContent: 'end' ,marginRight:'20px',minWidth:'60px'}}
//           checkedChildren="Pretty"
//           unCheckedChildren="Text"
//           defaultChecked={jsonview}
//           onChange={handleView}
//         />
//       </div>
//       {(jsonview && typeof value === "object") ? (
//         Object.entries(options).map(([key, elemento], index) => (
//           <Row key={index} gutter={8}>
//             <Col span={5}>
//               <Form.Item labelCol={{ span: 24 }} name={`no_save_key_${elemento.key}`} initialValue={elemento.key} label={index === 0 ? (campo==="variables")?'Fact':'Parameter' : ''}>
//                 <Input value={key} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'key')} />
//               </Form.Item>
//             </Col>
//             <Col span={17}>
//               <Form.Item labelCol={{ span: 24 }} name={`no_save_title_${elemento.key}`} initialValue={elemento.value} label={index === 0 ? 'Value' : ''}>
//                 <Input cmaxLength={maxLength} value={elemento.value} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'value')} />
//               </Form.Item>
//             </Col>
//             <Col span={2}>
//               {options.length > 1 ? (
//                 <Form.Item labelCol={{ span: 24 }} style={{ lineHeight: '30px' ,paddingTop:'5px'}} name={`no_save_delete_${index}`} label={index === 0 ? ' ' : ''}>
//                   <Tooltip title="Remove element">
//                     <Button size="small" type="primary" shape="circle" onClick={() => handleDelete(elemento.key)}>
//                       <Icons.RestOutlined />
//                     </Button>
//                   </Tooltip>
//                 </Form.Item>
//               ) : ''}
//             </Col>
//             </Row>
//
//         ))
//       ) : (
//         <>
//             <Form.Item labelCol={{ span: 24 }} name={`options_texto`} initialValue={(typeof options === 'object') ? JSON.stringify(options) : JSON.stringify(options).replace(/"/g, '')} label={'Manual value'}>
//                 <Input showCount={true} maxLength={255} placeholder="Please enter a value" onChange={(e) => handleInputChange('options', e.target.value, 'value')}/>
//             </Form.Item>
//           {(typeof options === 'object') && JSON.stringify(options)}
//         </>
//       )}
//
//     </>
//   );
// };
//
// export default OptionKeyValue;

const getMaxKey = (data) => {
  let maxKey = 0;
  data.forEach((obj) => {
    if (!isNaN(obj.key)) {
      const key = parseInt(obj.key, 10);
      maxKey = Math.max(maxKey, key) + 1;
    } else {
      const keyNumber = parseInt(maxKey, 10);
      maxKey = isNaN(keyNumber) ? 0 : keyNumber + 1;
    }
  });
  return maxKey;
};

const OptionKeyValue = ({ type, value, handleChange, campo }) => {
  const [options, setOptions] = useState(value || []);
  const [jsonview, setJsonView] = useState(
    typeof value === "object" ||
      (typeof value === "string" && value.includes("$"))
  );
  const limits = {
    switch: 20,
    optionButton: 3,
  };
  const maxLengths = {
    switch: 20,
    optionButton: 3,
    setVariable: 100,
  };

  const limit = limits[type] || 40;
  const maxLength = maxLengths[type] || 40;

  const execHandleChange = (...args) => {
    if (handleChange) {
      handleChange(...args);
    }
  };

  const handleInputChange = (key, newValue, field) => {
    if (jsonview && typeof value === "object") {
      let updatedValue = [...options];
      updatedValue[key][field] = newValue;
      execHandleChange(campo, updatedValue);
      setOptions(updatedValue);
    } else {
      execHandleChange(campo, newValue);
      setOptions([newValue]);
    }
  };

  const handle_resetValue = () => {
    let aux = [{ key: "variable", value: null }];
    setJsonView(true);
    execHandleChange(aux);
    setOptions(aux);
  };
  const handleView = () => {
    setJsonView(!jsonview);
  };

  const handleIncrease = () => {
    const aux = getMaxKey(options);
    const updatedValue = [...options, { key: `${aux}`, value: "New value" }];
    execHandleChange(updatedValue);
    setOptions(updatedValue);
  };

  const handleDelete = (key) => {
    const finalValue = options.filter((item) => item.key !== key);
    execHandleChange(finalValue);
    setOptions(finalValue);
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Tooltip title={"Reset values"}>
          <Button
            onClick={() => handle_resetValue()}
            size={"small"}
            style={{ alignSelf: "flex-start" }}
          >
            <Icons.ReloadOutlined />
          </Button>
        </Tooltip>

        <Switch
          style={{
            justifyContent: "end",
            minWidth: "60px",
          }}
          checkedChildren="Pretty"
          unCheckedChildren="Text"
          defaultChecked={jsonview}
          onChange={handleView}
        />
      </div>
      {jsonview && typeof value === "object" ? (
        Object.entries(options).map(([key, elemento], index) => (
          <Row key={index} gutter={2} align="bottom">
            <Col span={7}>
              <Form.Item
                labelCol={{ span: 24 }}
                name={`no_save_key_${elemento.key}`}
                initialValue={elemento.key}
                label={
                  index === 0 ? (campo === "variables" ? "Fact" : "Key") : ""
                }
              >
                <Input
                  value={key}
                  placeholder="Please enter a value"
                  onBlur={(e) => handleInputChange(key, e.target.value, "key")}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                labelCol={{ span: 24 }}
                name={`no_save_title_${elemento.key}`}
                initialValue={elemento.value}
                label={index === 0 ? "Value" : ""}
              >
                <Input
                  showCount={true}
                  maxLength={maxLength}
                  value={elemento.value}
                  placeholder="Please enter a value"
                  onBlur={(e) =>
                    handleInputChange(key, e.target.value, "value")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              {options.length > 1 ? (
                <Form.Item
                  labelCol={{ span: 24 }}
                  name={`no_save_delete_${index}`}
                >
                  <Tooltip title="Eliminar elemento">
                    <Button
                      size="small"
                      type="primary"
                      shape="circle"
                      onClick={() => handleDelete(elemento.key)}
                    >
                      <Icons.RestOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ))
      ) : (
        <>
          <Form.Item
            labelCol={{ span: 24 }}
            name={`options_texto`}
            initialValue={
              typeof value === "object"
                ? JSON.stringify(value)
                : JSON.stringify(value).replace(/"/g, "")
            }
            label={"Manual value"}
          >
            <Input
              showCount={true}
              maxLength={255}
              placeholder="Please enter a value"
              onChange={(e) =>
                handleInputChange("options", e.target.value, "value")
              }
            />
          </Form.Item>
          {typeof value === "object" && JSON.stringify(value)}
        </>
      )}
      {typeof value === "object" &&
      jsonview &&
      options.length >= 0 &&
      options.length < limit ? (
        <Row justify="end">
          <Col span={1}>
            <Tooltip title="Añadir elemento">
              <Button
                size="small"
                type="primary"
                shape="circle"
                onClick={handleIncrease}
              >
                <Icons.PlusOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default OptionKeyValue;
