import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_Request } from "../Services/APIService";
import { SettingOutlined } from "@ant-design/icons";
import DataTable from "../Components/DataTable";
import { FormBuilder } from "../Components/FormBuilder";
import { Spin, Tooltip } from "antd";
import { FormSecondaryWindow } from "./../Components/FormSecondaryWindow";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Services/notifications";

const dataTable_params = {
  entity: "users",
  main: "username",
  key: "id",
  new: ["username", "password", "role"],
  rows: 50,
  fieldsForm: [
    { name: "id", label: "Id", required: true, width: "100" },
    { name: "avatar", label: "Avatar", column: true, width: "150" },
    { name: "username", label: "Username", required: true },
    { name: "password", label: "Password", column: false, width: "100" },
    { name: "role", label: "role ", required: true, width: "150" },
  ],
  actions: [
    {
      icon: (
        <Tooltip title="Details">
          <SettingOutlined style={{ fontSize: "20px" }} />
        </Tooltip>
      ),
      link: "users",
    },
  ],
};

const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const [isSecondaryWindowEnabled, setSecondaryWindowEnabled] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const entity = "Users";

  const navigate = useNavigate();

  const handle_SecondaryWindowEnabled_close = () => {
    setSecondaryWindowEnabled(false);
  };
  const handle_SecondaryWindowEnabled_open = () => {
    setSecondaryWindowEnabled(true);
  };

  const handle_SecondaryWindowSave = (updateData) => {
    onFinish(updateData);
    setSecondaryWindowEnabled(false);
  };

  const onFinish = (values) => {
    if (values.context === "add") {
      API_Request("POST", entity.toLowerCase(), values.payload)
        .then((response) => {
          showSuccessNotification("Data saved!!!");
        })
        .catch((error) => {
          showErrorNotification(error.message);
          console.log(error);
        });
    } else {
      let url =
        entity.toLowerCase() +
        "/" +
        (values["id"] || data["id"] || values["customerId"]);
      API_Request("PUT", url, values)
        .then((response) => {
          showSuccessNotification("Data saved!!!");
        })
        .catch((error) => {
          showErrorNotification(error.message);
          console.log(error);
        });
    }
  };

  const handleDelete = (_value) => {
    setDeleteModalVisible(_value);
  };

  const handleDeleteConfirm = (id) => {
    // Aquí puedes realizar la lógica de borrado
    API_Request("DELETE", "users/" + data.formData.id)
      .then((response) => {
        showSuccessNotification("Data erased!!!");
      })
      .catch((error) => {
        showErrorNotification(error.message);
        console.log(error);
      })
      .finally(() => {
        setDeleteModalVisible(false);
        navigate(-1);
      });
  };

  useEffect(() => {
    setLoading(true);
    API_Request("GET", id ? "/" + entity + "/" + id : "/" + entity)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (data === undefined || (data.length < 1 && id !== "new")) {
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );
  }

  return id !== undefined && id !== "new" ? (
    <>
      <h1>{entity.slice(0, -1)} details</h1>
      {!loading && (
        <>
          <FormBuilder
            entity={entity.toLowerCase()}
            formData={data}
            formParams={dataTable_params.fieldsForm}
          />
        </>
      )}
    </>
  ) : (
    id !== "new" && (
      <>
        <h1>{entity}</h1>
        <DataTable
          loading={loading}
          onChange={null}
          data={data}
          params={dataTable_params}
          onCreateClick={handle_SecondaryWindowEnabled_open}
        />
        {isSecondaryWindowEnabled && (
          <FormSecondaryWindow
            title={"Add"}
            width={"25%"}
            context={"add"}
            onUpdate={handle_SecondaryWindowEnabled_close}
            onSave={handle_SecondaryWindowSave}
            onClose={handle_SecondaryWindowEnabled_close}
            data={dataTable_params}
          />
        )}
      </>
    )
  );
};

export default UsersPage;
