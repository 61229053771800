import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_Request } from "../Services/APIService";
import { SettingOutlined } from "@ant-design/icons";
import DataTable from "../Components/DataTable";
import { FormBuilder } from "../Components/FormBuilder";
import { Spin } from "antd";

const form_params = [
  { name: "id", label: "Bot It" },
  { name: "name", label: "Name of bot" },
];

const form_new_params = [{ name: "name", required: true }];

const dataTable_params = {
  entity: "bots",
  main: "name",
  rows: 100,
  columns: ["name"],
  actions: [
    {
      icon: <SettingOutlined />,
      link: "bots",
    },
  ],
};

const BotsPage = () => {
  const [loading, setLoading] = useState(false);
  const [total_data, settotal_data] = useState([]);
  //const [id_DataTable, setid_DataTable] = useState(null);
  //const [data_DataTable, setdata_DataTable] = useState(null);
  const { id } = useParams();
  const entity = "Bots";

  //let id_final = id || id_DataTable

  useEffect(() => {
    setLoading(true);
    if (id !== "new") {
      let url = id
        ? "/" + entity.toLowerCase() + "/" + id
        : "/" + entity.toLowerCase();
      API_Request("GET", url)
        .then((result) => {
          settotal_data(result.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id]);
  if (total_data.length < 1)
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );

  let render_aux = <></>;
  if (id === "new") {
    render_aux = (
      <>
        <h1>Add {module || entity.slice(0, -1)} </h1>
        {!loading && (
          <FormBuilder
            entity={entity.toLowerCase()}
            formData={false}
            formParams={form_new_params}
          />
        )}
      </>
    );
    return render_aux;
  }

  return id !== undefined ? (
    <>
      <h1>{entity.slice(0, -1)}</h1>
      {!loading && (
        <FormBuilder
          entity={entity}
          formData={total_data[0]}
          formParams={form_params}
        />
      )}
    </>
  ) : (
    <>
      <h1>{entity}</h1>
      <DataTable
        loading={loading}
        data={total_data}
        params={dataTable_params}
      />
    </>
  );
};

export default BotsPage;
